import { createSlice } from '@reduxjs/toolkit';
import { get3rdPartyResponse, listSyncLogs } from '../../api/gatewise_api';
import { generateExcel } from './utils';
import { notifySuccess } from '../notifications/notificationsSlice';

const syncLogSlice = createSlice({
  name: 'syncLog',
  initialState: {
    page: {
      items: [],
      total: 0,
    },
  },
  reducers: {
    setPage: (state, action) => {
      const { page, error } = action.payload;
      state.page = page;
      state.error = error;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setErrorNotifications: (state, action) => {
      state.notifications = {
        error: action.payload,
      };
    },
  },
});

export const { setPage, setLoading, setErrorNotifications } = syncLogSlice.actions;

export default syncLogSlice.reducer;

export const loadPage = (communityId, offset, limit) => async (dispatch) => {
  if (!communityId) return;

  try {
    dispatch(setLoading(true));
    const { items, total } = await listSyncLogs(communityId, offset, limit);
    dispatch(setPage({ page: { items, total }, error: null }));
  } catch (error) {
    dispatch(
      setPage({
        page: {
          items: [],
          total: 0,
        },
        error: error.toString(),
      }),
    );
  } finally {
    dispatch(setLoading(false));
  }
};

export const exportToExcel = (community) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const { items } = await listSyncLogs(community.id, 0, 99999);
    generateExcel(items, community);
    dispatch(notifySuccess('Export completed'));
  } catch (err) {
    dispatch(setErrorNotifications(err.toString()));
  } finally {
    dispatch(setLoading(false));
  }
};

export const downloadLastResponse = (community) => async (dispatch) => {
  try {
    const url = await get3rdPartyResponse(community.id, community.integration.type);
    let a = document.createElement('a');
    a.href = url;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  } catch (err) {
    dispatch(setErrorNotifications(err.toString()));
  }
};
